.quill{
  display: flex;
  flex-direction: column; 
}

.ql-container {
    box-sizing: border-box;
    /* font-size: 13px; */
    font-size: 16px;
    height: 100%;
    margin: 0px;
    position: relative;
    border: 0 !important;
    font-family: Lexend, sans-serif;
    font-weight: 400;
    
  }

  .ql-toolbar{
    border: 0 !important;
    order: 1;
    padding: 0px !important;
    margin-top: 20px;
  }

.ql-editor{
  padding: 0;
  order: 0;
  line-height: 2.1;
}

.ql-editor ul{
  padding-left:0 !important
}

.ql-editor ul li{
  padding-left: 10px !important
}

.ql-editor ul li{
  list-style-type: disc;
}


.ql-blank::before{
  left:auto !important;
  right:auto !important;
  color: rgb(0 0 0 / 28%);
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button{
  background-color: whitesmoke;
  width: 28px;
  height: 28px;
  margin-right: 8px;
}